/* You can add global styles to this file, and also import other style files */

html, body
    height: 100%


body
    display: flex
    flex-direction: column

app-root
    display: flex
    flex-direction: column
    flex: 1 0 auto
    align-items: center

@font-face 
  font-family: 'unispace'
  src: url('assets/unispace.ttf')